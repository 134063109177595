import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import MoneyInput from "../../MoneyInput";
import ModalCenter from "../../ModalCenter/ModalCenter";

export default function DiscountModal(props) {
  let { setProducts, products, open, close } = props;
  const [discountPrice, setDiscountPrice] = useState(0);

  const addDiscount = () => {
    let discount = Math.abs(discountPrice) * -1;

    let discountProduct = {
      sku: "Discount",
      color: "N/A",
      title: "Discount",
      quantity: 1,
      status: "Special Order",
      custom: true,
      vendor: {
        contactInformation: {
          contacts: [],
        },
        purchaseOrders: [],
        _id: "5f0cc1a4ff89002ee05b7b3e",
        visible: true,
        showPricing: true,
        name: "Vancouver Woodworks",
        vendorCodes: [],
        date: "2020-07-13T20:18:44.580Z",
        __v: 0,
      },
      notes: [],
      price: discount,
    };
    setProducts([...products, { ...discountProduct }]);
    close();
  };

  return (
    <ModalCenter open={open} close={close}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Add Discount</h3>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            id="standard-basic"
            label="Discount"
            variant="outlined"
            value={discountPrice}
            onChange={(e) => {
              setDiscountPrice(e.target.value);
            }}
            InputProps={{
              inputComponent: MoneyInput,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={addDiscount}>
            Add Discount
          </Button>
        </Grid>
      </Grid>
    </ModalCenter>
  );
}
